import { useEffect, useState } from 'react';
import {
  deleteSalaryCalculator,
  fetchCalculationPrint,
  fetchCalculationSheet,
  fetchSalaryCalculator,
  fetchSalaryCalculatorDate,
} from '../services/fetchCalculationSheet';
import { useSelector } from 'react-redux';

const useCalculationSheet = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [error, setError] = useState('');
  const [errorMess, setErrorMess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [calculationSheet, setCalculationSheet] = useState(null);
  const [calculationData, setCalculationData] = useState(null);
  const [totals, setTotals] = useState(null);
  const [calculationPrintData, setCalculationPrintData] = useState(null);
  const getCalculationSheet = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchCalculationSheet(currentCompanyID, empNo);
      setCalculationSheet(data);
      setCalculationData(data?.emplDatas);
      setTotals(data?.totals);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCalculationPrint = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchCalculationPrint(currentCompanyID, empNo);
      setCalculationPrintData(data);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSalaryCalculator = async (empNo, salaryMonth) => {
    if (!empNo || !salaryMonth) {
      setError('Employee number and salary month are required');
      return;
    }
    setIsLoading(true);
    try {
      const data = await fetchSalaryCalculator(
        currentCompanyID,
        empNo,
        salaryMonth,
      );

      setTotals(data?.totals);
      setCalculationData(data?.emplCalcs);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSalaryCalculatorDate = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchSalaryCalculatorDate(currentCompanyID, query);
      setCalculationData(data?.emplCalcs);
      setTotals(data?.totals);
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeCalculationSheetItem = async (
    salaryEntryID,
    empNo,
    closeFormCallback,
  ) => {
    setIsLoading(true);
    let filteredData = calculationData?.filter(
      (item) => item.salaryEntryID !== salaryEntryID,
    );
    try {
      const { errorCode, message } = await deleteSalaryCalculator(
        salaryEntryID,
      );

      if (errorCode === 0) {
        setCalculationData([...filteredData]);
        getCalculationSheet(empNo);
        closeFormCallback();
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    getCalculationSheet,
    getSalaryCalculator,
    removeCalculationSheetItem,
    calculationSheet,
    getSalaryCalculatorDate,
    calculationData,
    getCalculationPrint,
    calculationPrintData,
    isLoading,
    error,
    errorMess,
    setErrorMess,
    totals,
    setTotals,
  };
};

export default useCalculationSheet;
