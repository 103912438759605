import React, { useEffect, useState } from 'react';
import Header from '../../../App/Components/Header/Header';
import AccountingFormulationTable from './AccountingFormulationTable';
import AccountingFormulationAction from './AccountingFormulationAction';
import './style.scss';
import useAccountingFormulations from '../../hooks/useAccountingFormulations';
import AccountingFormulationBtn from './AccountingFormulationBtn';
import Alert from '../../../App/Components/Alert';
import { updateButtonStates } from './buttonStateHelpers';
import { ConvertAccountDataExcel } from '../../Config/excelConfig';
const AccountingFormulations = () => {
  const {
    getAccountingFormulations,
    getNewFormulations,
    saveAccountingFormulations,
    deleteAccountingFormulation,
    accountFormulationData,
    formulationsTable,
    setFormulationsTable,
    getDateOfFormation,
    getSalaryMonths,
    salaryMonthData,
    errorMess,
    setErrorMess,
  } = useAccountingFormulations();

  const [query, setQuery] = useState({
    salaryMonthName: '',
    years: '',
    month: '',
    salaryDate: '',
    yearMonth: '',
  });

  const [isDisabledButtons, setIsDisabledButtons] = useState({
    newFormulations: true,
    print: true,
    remove: true,
    confirm: true,
  });

  useEffect(() => {
    getAccountingFormulations();
  }, []);

  useEffect(() => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      salaryDate: formulationsTable?.salaryDate,
      years: accountFormulationData?.currentYear,
    }));
  }, [formulationsTable?.salaryDate, accountFormulationData]);

  useEffect(() => {
    updateButtonStates(formulationsTable, query, setIsDisabledButtons);
  }, [formulationsTable, query]);

  const resetFields = () => {
    setQuery({
      salaryMonthName: '',
      years: '',
      month: '',
      salaryDate: '',
      yearMonth: '',
    });
  };

  const resetFormulationData = () => {
    setFormulationsTable(null);
  };

  const onNewFormulationsHandler = async () => {
    try {
      await getNewFormulations(query);
    } catch (err) {
      console.error('An error occurred while executing formulations:', err);
    }
  };

  const onConfirmHandler = async () => {
    const param = {
      year: query.years,
      month: query.month,
    };
    try {
      await saveAccountingFormulations(formulationsTable, param);
    } catch (err) {
      console.error('An error occurred while saving formulations:', err);
    }
  };

  const onRemoveHandler = async () => {
    try {
      await deleteAccountingFormulation(query.salaryDate, resetFields);
      resetFields();
    } catch (err) {
      console.error('An error occurred while removing formulation:', err);
    }
  };

  const formulationExcelData = ConvertAccountDataExcel(
    formulationsTable?.statements,
  );

  return (
    <>
      <Header
        pageTitle="Հաշվապահական ձևակերպումներ"
        headerBtn={false}
        showCalculationDate={true}
      />
      <AccountingFormulationAction
        query={query}
        setQuery={setQuery}
        onNewFormulationsHandler={onNewFormulationsHandler}
        accountFormulationData={accountFormulationData}
        getDateOfFormation={getDateOfFormation}
        formulationsTable={formulationsTable?.statements}
        resetFormulationData={resetFormulationData}
        getSalaryMonths={getSalaryMonths}
        salaryMonthData={salaryMonthData}
        isDisabledButtons={isDisabledButtons}
        formulationExcelData={formulationExcelData}
        saveAndPrintSign={formulationsTable?.saveAndPrintSign}
      />
      <AccountingFormulationTable
        formulationsTable={formulationsTable?.statements}
      />

      {formulationsTable?.statements?.length > 0 && (
        <AccountingFormulationBtn
          onConfirmHandler={onConfirmHandler}
          onRemoveHandler={onRemoveHandler}
          isDisabledButtons={isDisabledButtons}
          saveAndPrintSign={formulationsTable?.saveAndPrintSign}
        />
      )}

      <Alert description={errorMess} onClose={setErrorMess} />
    </>
  );
};

export default AccountingFormulations;
