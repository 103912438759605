import React from 'react';
import SearchBackground from '../../../App/Components/SearchForm/SearchBackground';
import { searchFields } from './searchConfig';
import Input from '../../Input/Input';
import { SelectControl } from '../../../App/Components/SelectControl/SelectControl';
import { ExportExcel } from '../../../App/Components/Excelexport/Excelexport';

const AdvancedSearch = ({
  query,
  setQuery,
  employeesFormData,
  getEmployeesExcelData,
}) => {
  let dropDownOptions = [];

  const handleDropdownChange = (fieldName, selectedOption, filteredName) => {
    if (!selectedOption) {
      selectedOption = {
        value: '',
        id: null,
      };
      setQuery({ depName: '' });
    }
    setQuery((prevQuery) => ({
      ...prevQuery,
      [fieldName]: {
        ...prevQuery[fieldName],
        value: selectedOption.value,
        id: selectedOption.id,
      },
      [filteredName]: selectedOption.value,
    }));
  };

  const onChange = (e, fieldName) => {
    const val = e.target.value;
    setQuery({ ...query, [fieldName]: val });
  };

  return (
    <SearchBackground>
      <div className="employees-filter-block">
        <div className="employees-filter-flex">
          {searchFields?.map((field) => {
            const {
              fieldType,
              fieldName,
              label,
              name,
              required,
              Children,
              optionName,
              dropdownTitle,
              filteredName,
              type,
            } = field;

            if (typeof Children === 'function') {
              if (employeesFormData) {
                dropDownOptions = Children(employeesFormData[optionName]);
              }
            }

            switch (fieldType) {
              case 'input':
                return (
                  <div key={name} className="input-container">
                    <Input
                      type={type}
                      inputTitle={label}
                      name={name}
                      requiredFiled={required}
                      onChange={(e) => onChange(e, name)}
                      className="has-border"
                    />
                  </div>
                );
              case 'dropdown':
                return (
                  <div key={fieldName} className="select-container">
                    <SelectControl
                      value={{
                        label: query[filteredName],
                        query: query[filteredName],
                      }}
                      selectBoxTitle={dropdownTitle}
                      requiredFiled={required}
                      options={dropDownOptions || []}
                      onChange={(selectedOption) =>
                        handleDropdownChange(
                          fieldName,
                          selectedOption,
                          filteredName,
                        )
                      }
                    />
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>

        <div className="G-flex-justify-end">
          <ExportExcel
            //  excelData={dataToExcel}
            fileName="Աշխատակիցներ"
            getExcelData={getEmployeesExcelData}
          />
        </div>
      </div>
    </SearchBackground>
  );
};

export default AdvancedSearch;
