import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  addEmployees,
  deleteEmployees,
  fetchEmployees,
  fetchEmployeesAddEditData,
  fetchEmployeesExcelData,
  fetchIsAllowDelete,
  updateEmployees,
} from '../services/fetchEmployees';
import {
  addFormDataAction,
  deleteEmployeeAction,
  fetchEmployeesAction,
  updateEmployeeAction,
} from '../../App/Redux/SalaryStor/employeesSlices/useAction';
import { updateWithEmployeesSalaryAction } from '../../App/Redux/SalaryStor/workWithEmployeesSlices/useAction';

const useEmployeesForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const dispatch = useDispatch();
  const { id } = useSelector((state) => state.employeesData?.filteredItem);
  const { currentCompanyID } = useSelector((state) => state.auth);
  const employees = useSelector((state) => state.employeesData?.employees);
  const employeesFormData = useSelector(
    (state) => state.employeesData?.employeesForm,
  );
  let location = useLocation();
  const isUpdate = location.pathname === '/Actions_with_employees';
  const [isAllowDelete, setIsAllowDelete] = useState(false);

  const fetchEmployeesData = async (query, pageNumber) => {
    setIsLoading(true);
    try {
      const data = await fetchEmployees(
        currentCompanyID,
        query,
        id,
        pageNumber,
      );
      dispatch(fetchEmployeesAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getEmployeesExcelData = async (param) => {
    setIsLoading(true);
    try {
      const data = await fetchEmployeesExcelData(currentCompanyID, param);
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addEmployee = async (
    employeeData,
    closeFormCallback,
    query,
    pageNumber,
  ) => {
    try {
      const { errorCode, message } = await addEmployees(employeeData);
      if (errorCode === 0) {
        fetchEmployeesData(query, pageNumber);
        closeFormCallback();
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateEmployee = async (employeeData, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await updateEmployees(employeeData);

      if (errorCode === 0 && !message) {
        if (isUpdate) {
          dispatch(updateWithEmployeesSalaryAction(employeeData));
        }
        closeFormCallback();
        dispatch(updateEmployeeAction(employeeData));
      }

      if (errorCode > 0 && message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getIsAllowDelete = async (employeeId) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await fetchIsAllowDelete(employeeId);

      if (errorCode === 0) {
        setIsAllowDelete(true);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteEmployee = async (employeeId, closeFormCallback) => {
    setIsLoading(true);
    try {
      const { errorCode, message } = await deleteEmployees(employeeId);

      if (errorCode === 0) {
        dispatch(deleteEmployeeAction(employeeId));
        closeFormCallback();
      }
      if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmployeesFormData = async (currentCompanyID, employeeID) => {
    setIsLoading(true);
    try {
      const data = await fetchEmployeesAddEditData(
        currentCompanyID,
        employeeID,
      );
      dispatch(addFormDataAction(data));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
      setIsLoading(false);
    };
  }, []);

  return {
    employees,
    fetchEmployees,
    updateEmployee,
    deleteEmployee,
    fetchEmployeesData,
    fetchEmployeesFormData,
    addEmployee,
    employeesFormData,
    error,
    isLoading,
    errorMess,
    setErrorMess,
    getIsAllowDelete,
    isAllowDelete,
    setIsAllowDelete,
    getEmployeesExcelData,
  };
};

export default useEmployeesForm;
