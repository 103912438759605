import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';

import {
  getOperationsAction,
  loadingAction,
} from '../../Redux/operationsSlices/useAction';
import { dataTimeFormat, initialDate } from '../../Utilities/Utilities';
import OperationsTable from './OperationsTable';
import './operations.scss';
import EventAddForm from './EventAddForm';
import AdvanceSearch from './AdvanceSearch';
import { queryValue } from '../../Helper/urlHelper';
import Alert from '../../Components/Alert';
import useFetchExcel from '../../Hooks/useFetchExcel';

const GetOperationsData = memo(() => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const { operationsData, isLoading } = useSelector(
    (state) => state.getOperationsData,
  );

  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());

  const [pageNumber, setPageNumber] = useState(0);
  const [searchValue, setSearchValue] = useState({});
  const dispatch = useDispatch();
  const [openAddForm, setOpenAddForm] = useState(false);
  const [errorHandler, setErrorHandler] = useState('');
  const { fetchExcelData } = useFetchExcel();
  const getData = useCallback(
    (pageNumber) => {
      const dateStartFormat = dataTimeFormat(dateStart);
      const dateEndFormat = dataTimeFormat(dateEnd);
      const searchParams = queryValue(searchValue);

      dispatch(loadingAction(true));
      getRequest(
        `getentries?companyID=${currentCompanyID}&pageNumber=${
          pageNumber + 1
        }&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`,
        searchParams,
      )
        .then((res) => {
          dispatch(getOperationsAction(res.data));
        })
        .catch((err) => {
          console.log('Err' + err);
        })
        .finally(() => {
          dispatch(loadingAction(false));
        });
    },
    [currentCompanyID, dateEnd, dateStart, dispatch, searchValue],
  );

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, currentCompanyID, dispatch]);

  const openAddFormHandler = () => {
    setOpenAddForm(true);
  };

  const getExcelData = () => {
    const dateStartFormat = dataTimeFormat(dateStart);
    const dateEndFormat = dataTimeFormat(dateEnd);

    const searchParams = new URLSearchParams(
      queryValue(searchValue),
    ).toString();

    const url = `getEntriesForExcel?companyID=${currentCompanyID}&dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}&${searchParams}`;

    return fetchExcelData(url);
  };

  return (
    <div className="L-operations-page">
      <Header pageTitle="Գործառնություններ" handelClick={openAddFormHandler} />

      <AdvanceSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        getData={getData}
        pageNumber={pageNumber}
        mainData={operationsData}
        setErrorHandler={setErrorHandler}
        setPageNumber={setPageNumber}
        getExcelData={getExcelData}
      />

      <OperationsTable
        mainData={operationsData?.entries}
        loading={isLoading}
        getData={getData}
        pageNumber={pageNumber}
      />
      <Pagination
        pageCount={operationsData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
      {openAddForm && (
        <EventAddForm
          closeModal={setOpenAddForm}
          getData={getData}
          pageNumber={pageNumber}
          setErrorHandler={setErrorHandler}
        />
      )}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </div>
  );
});

export default GetOperationsData;
