import React, { useState, useEffect } from 'react';
import { getRequest, putRequest } from '../../Api/Api';
import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
import { useDispatch } from 'react-redux';
import { closeDocumentAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
import Alert from '../../Components/Alert';

const CloseDocument = ({ closeModal, closeDocumentData }) => {
  const { cashMoneyID, isClosed } = closeDocumentData || {};
  const handleClose = () => closeModal(false);
  const [errorHandler, setErrorHandler] = useState('');
  const [isAllowClose, setIsAllowClose] = useState(false);
  const [docNotDone, setDocNotDone] = useState('');
  const [isMounted, setIsMounted] = useState(true);
  const [openClosedDoc, setOpenClosedDoc] = useState(false);
  const dispatch = useDispatch();
  const openClosedDocHandler = async () => {
    try {
      if (isClosed) {
        const firstRequestUrl = `letOpenCashMoney?CashMoneyID=${cashMoneyID}`;
        const firstRes = await getRequest(firstRequestUrl);
        const { errorCode: firstErrorCode, message: firstMessage } =
          firstRes.data || {};

        if (firstErrorCode === 0) {
          setOpenClosedDoc(true);
        } else if (firstErrorCode > 0) {
          setErrorHandler(firstMessage);
        }
      }
    } catch (error) {
      console.error('Error in openClosedDoc:', error);
    }
  };

  useEffect(() => {
    openClosedDocHandler();
    return () => {};
  }, []);

  const agreeOpenDocument = async () => {
    const secondRequestUrl = `openCashMoney?CashMoneyID=${cashMoneyID}`;

    try {
      const secondRes = await putRequest(secondRequestUrl);
      const { errorCode, message } = secondRes.data || {};

      if (errorCode === 1) {
        dispatch(closeDocumentAction(cashMoneyID));
        setOpenClosedDoc(false);
      } else if (errorCode > 1) {
        setErrorHandler(message);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    async function checkAllowClose() {
      try {
        if (cashMoneyID && !isClosed) {
          const url = `letCloseCashMoney?CashMoneyID=${cashMoneyID}`;
          const res = await getRequest(url);
          const { errorCode, message } = res.data || {};

          if (errorCode === 111 || errorCode === 112 || errorCode === 108) {
            setIsAllowClose(false);
            setErrorHandler(message);
          } else if (errorCode === 1) {
            setIsAllowClose(true);
            setDocNotDone('Փաստաթղթում կա չկատարված գործառնություն, փակել՞');
          } else if (errorCode === 0) {
            setIsAllowClose(true);
          }
        }
      } catch (err) {
        console.error('Error checking allow delete:', err);
      }
    }
    checkAllowClose();
  }, [cashMoneyID]);

  const closeDocumentHandler = () => {
    putRequest(`closeCashMoney?cashMoneyID=${cashMoneyID}`)
      .then((res) => {
        if (res.data.errorCode === 1) {
          dispatch(closeDocumentAction(cashMoneyID));
          handleClose();
        }
        if (res.data.errorCode > 0 && isMounted) {
          setErrorHandler(res.data.message);
        }
      })
      .catch((err) => {
        console.log('err' + err);
      });
  };

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <>
      {isAllowClose && (
        <AcceptOrCancelModal
          closeModal={closeModal}
          confirmClick={closeDocumentHandler}
          modalContent={
            docNotDone
              ? docNotDone
              : 'Համոզվա՞ծ եք, որ ուզում եք փակել փաստաթուղթ'
          }
        />
      )}

      {openClosedDoc ? (
        <AcceptOrCancelModal
          closeModal={setOpenClosedDoc}
          confirmClick={agreeOpenDocument}
          modalContent="Համոզվա՞ծ եք, որ ուզում եք բացել փակված փաստաթուղթը"
        />
      ) : null}
      <Alert description={errorHandler} onClose={setErrorHandler} />
    </>
  );
};

export default CloseDocument;

// import React, { useState, useEffect } from 'react';
// import { getRequest, putRequest } from '../../Api/Api';
// import AcceptOrCancelModal from '../../Components/AcceptOrCancelModal/AcceptOrCancelModal';
// import { useDispatch } from 'react-redux';
// import { closeDocumentAction } from '../../Redux/accountForSpendingMoneySlices/useAction';
// import Alert from '../../Components/Alert';

// const CloseDocument = ({ closeModal, closeDocumentData }) => {
//   const { cashMoneyID, isClosed } = closeDocumentData || {};
//   const handleClose = () => closeModal(false);
//   const [errorHandler, setErrorHandler] = useState('');
//   const [openClosedDoc, setOpenClosedDoc] = useState(false);
//   const [isAllowClose, setIsAllowClose] = useState(false);
//   const [docNotDone, setDocNotDone] = useState('');
//   const [isMounted, setIsMounted] = useState(true);
//   const dispatch = useDispatch();
//   const openClosedDocHandler = async () => {
//     try {
//       if (!isClosed) {
//         const firstRequestUrl = `letOpenCashMoney?CashMoneyID=${cashMoneyID}`;
//         const firstRes = await getRequest(firstRequestUrl);
//         const { errorCode: firstErrorCode, message: firstMessage } =
//           firstRes.data || {};

//         if (firstErrorCode === 0) {
//           setOpenClosedDoc(true);
//         } else if (firstErrorCode > 0) {
//           setErrorHandler(firstMessage);
//         }
//       }
//     } catch (error) {
//       console.error('Error in openClosedDoc:', error);
//     }
//   };

//   useEffect(() => {
//     openClosedDocHandler();
//   }, [isClosed, cashMoneyID, openClosedDoc]);

//   const agreeOpenDocument = async () => {
//     try {
//       if (openClosedDoc) {
//         const secondRequestUrl = `openCashMoney?CashMoneyID=${cashMoneyID}`;
//         const secondRes = await putRequest(secondRequestUrl);
//         const { errorCode: secondErrorCode, message: secondMessage } =
//           secondRes.data || {};
//         if (secondErrorCode === 1) {
//           dispatch(closeDocumentAction(cashMoneyID));
//         }

//         if (secondErrorCode > 0) {
//           setErrorHandler(secondMessage);
//         }
//       }
//     } catch (error) {
//       console.error('Error in agreeOpenDocument:', error);
//     }
//   };

//   useEffect(() => {
//     async function checkAllowClose() {
//       try {
//         if (cashMoneyID && !isClosed) {
//           const url = `letCloseCashMoney?CashMoneyID=${cashMoneyID}`;
//           const res = await getRequest(url);
//           const { errorCode, message } = res.data || {};

//           if (errorCode > 1) {
//             //setIsAllowClose(false);
//             setOpenClosedDoc(true);
//           } else if (errorCode === 1) {
//             setIsAllowClose(true);
//             setDocNotDone('Փաստաթղթում կա չկատարված գործառնություն, փակել՞');
//           } else if (errorCode === 0) {
//             setIsAllowClose(true);
//           }
//         }
//       } catch (err) {
//         console.error('Error checking allow delete:', err);
//       }
//     }
//     checkAllowClose();
//   }, [cashMoneyID]);

//   const closeDocumentHandler = () => {
//     putRequest(`closeCashMoney?cashMoneyID=${cashMoneyID}`)
//       .then((res) => {
//         if (res.data.errorCode === 1) {
//           dispatch(closeDocumentAction(cashMoneyID));
//           handleClose();
//         }
//         if (res.data.errorCode > 0 && isMounted) {
//           setErrorHandler(res.data.message);
//         }
//       })
//       .catch((err) => {
//         console.log('err' + err);
//       });
//   };

//   useEffect(() => {
//     return () => {
//       setIsMounted(false);
//     };
//   }, []);

//   return (
//     <>
//       {isAllowClose && (
//         <AcceptOrCancelModal
//           closeModal={closeModal}
//           confirmClick={closeDocumentHandler}
//           modalContent={
//             docNotDone
//               ? docNotDone
//               : 'Համոզվա՞ծ եք, որ ուզում եք փակել փաստաթուղթ'
//           }
//         />
//       )}

//       {openClosedDoc ? (
//         <AcceptOrCancelModal
//           closeModal={setOpenClosedDoc}
//           confirmClick={agreeOpenDocument}
//           modalContent="Համոզվա՞ծ եք, որ ուզում եք բացել փակվաց փաստաթուղթ"
//         />
//       ) : null}

//       <Alert description={errorHandler} onClose={setErrorHandler} />
//     </>
//   );
// };

// export default CloseDocument;
