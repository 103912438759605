import React from 'react';
import './style.scss';
import { dateFormat } from '../../../App/Utilities/Utilities';

const ProvisionWrap = (WrappedComponent) => {
  return function ProvisionWrap({ title, date, ...props }) {
    return (
      <div className="provision-box">
        <div className="provision-box-content">
          <h2>{title}</h2>
          <div className="provisions-title">
            <p> Գործում է' </p>
            <span>{date ? dateFormat(date) : ''}</span>
          </div>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };
};

export default ProvisionWrap;
