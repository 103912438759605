import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../Api/Api';
import Header from '../../Components/Header/Header';
import Pagination from '../../Components/Pagination/Pagination';

import { urlEncodeQueryParams, queryValue } from '../../Helper/urlHelper';
import {
  dataTimeFormat,
  initialDate,
  printHandler,
} from '../../Utilities/Utilities';
import DocumentSearchBar from './DocumentSearchBar';
import DocumentTable from './DocumentTable';
import './document.scss';
import useFetchExcel from '../../Hooks/useFetchExcel';

const GetDocumentData = () => {
  const { currentCompanyID, startDate } = useSelector((state) => state.auth);
  const { fetchExcelData } = useFetchExcel();
  const [pageNumber, setPageNumber] = useState(0);
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState({});
  const [dateStart, setDateStart] = useState(new Date(startDate));
  const [dateEnd, setDateEnd] = useState(initialDate());
  const dateStartFormat = dataTimeFormat(dateStart);
  const dateEndFormat = dataTimeFormat(dateEnd);
  const dateUrl = `dateStart=${dateStartFormat}&dateEnd=${dateEndFormat}`;
  const dispatch = useDispatch();

  const getData = (pageNumber) => {
    const searchParams = queryValue(searchValue);
    setLoading(true);
    getRequest(
      `getDocuments?companyID=${currentCompanyID}&pageNumber=${
        pageNumber + 1
      }&${dateUrl}`,
      searchParams,
    )
      .then((res) => {
        setDocumentData(res.data);
      })
      .catch((err) => {
        console.log('Err' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData(pageNumber);
  }, [pageNumber, dateUrl]);

  const handlePrint = () => {
    const printResult = urlEncodeQueryParams(searchValue);
    printHandler(
      `PrintDocuments?companyID=${currentCompanyID}&${dateUrl}&${printResult}`,
      dispatch,
    );
  };

  const getExcelData = () => {
    const searchParams = new URLSearchParams(
      queryValue(searchValue),
    ).toString();

    const url = `getDocumentsForExcel?companyID=${currentCompanyID}&${dateUrl}&${searchParams}`;

    return fetchExcelData(url);
  };
  return (
    <>
      <Header headerBtn={false} pageTitle="Փաստաթղթեր" />

      <DocumentSearchBar
        dateStart={dateStart}
        setDateStart={setDateStart}
        dateEnd={dateEnd}
        setDateEnd={setDateEnd}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        getData={getData}
        pageNumber={pageNumber}
        mainData={documentData}
        printHandler={handlePrint}
        setPageNumber={setPageNumber}
        getExcelData={getExcelData}
      />

      <DocumentTable mainData={documentData?.documents} loading={loading} />
      <Pagination
        pageCount={documentData?.pageCount?.pageCount}
        setPageNumber={setPageNumber}
        currentPage={pageNumber}
      />
    </>
  );
};

export default GetDocumentData;
