import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  fetchIsExecuteMakeAddition,
  fetchMakeAdditionalSave,
  saveMakeAddition,
} from '../services/fetchMakeAdditionalSave';

const useMakeAdditionalSave = () => {
  const { currentCompanyID } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMess, setErrorMess] = useState(null);
  const [makeAdditionalData, setMakeAdditionalData] = useState([]);
  const [additionalSaveData, setAdditionalSaveData] = useState([]);
  const getMakeAdditionalSave = async (empNo) => {
    setIsLoading(true);
    try {
      const data = await fetchMakeAdditionalSave(currentCompanyID, empNo);
      setMakeAdditionalData(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getIsExecuteData = async (query) => {
    setIsLoading(true);
    try {
      const data = await fetchIsExecuteMakeAddition(currentCompanyID, query);
      setAdditionalSaveData(data);
    } catch (error) {
      console.log('error', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addMakeAddition = async (updateData, query) => {
    try {
      const { errorCode, message } = await saveMakeAddition(updateData);
      if (errorCode === 0) {
        getIsExecuteData(query);
      } else if (message) {
        setErrorMess(message);
      }
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    return () => {
      setError('');
      setErrorMess('');
    };
  }, []);

  return {
    isLoading,
    error,
    errorMess,
    getMakeAdditionalSave,
    makeAdditionalData,
    getIsExecuteData,
    additionalSaveData,
    setAdditionalSaveData,
    addMakeAddition,
  };
};

export default useMakeAdditionalSave;
