import { useEffect, useState } from 'react';
import { postRequest } from '../Api/Api';

const useVerify = (captchaValue) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isVerify, setIsVerify] = useState(null);

  useEffect(() => {
    const verify = async () => {
      setIsLoading(true);

      try {
        const captchaResponse = await postRequest('verifyCaptcha', {
          captchaValue,
        });
        const captchaResult = captchaResponse.data.success;

        setIsVerify(captchaResult);
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    if (captchaValue) {
      verify();
    }
  }, [captchaValue]);

  return { isVerify, isLoading };
};

export default useVerify;
